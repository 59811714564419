export const MIN_PRICE_TO_FREE_DPD_DELIVERY = 98.99;
export const CATEGORIES_TO_DISPLAY_DPD_AND_RETURNS_BADGES = ['Footwear'];
export const BADGES_TIPS = [
    {
        name: 'priceGuaranteed',
        img: 'guarantee.png',
        tip: 'Prices Guaranteed',
        information: 'We check prices daily!',
        color: 'orange',
    },
    {
        name: 'freeReturns',
        img: 'return.png',
        tip: 'Free Returns',
        information: 'within 28 days',
        color: 'green',
    },
    {
        name: 'dpdDelivery',
        img: 'fedex-delivery.png',
        tip: 'Free FedEx Delivery',
        information: 'on this item!',
        color: 'red',
    },
];
export const MORE_FROM_BRAND_RESULTS = 6;
export const CONFIGURABLE_PRODUCTS_CODE = '9017';
export const BESTSELLERS_CATEGORY = 'Best Sellers';
export const EXCLUDED_PRODUCTS_CATEGORY = 'PromoBunker';
export const NUMBER_OF_SLIDES_TO_SHOW = 3;
